const proyectos = [
    {id: 1,vURL: "assets/img/proyectos/31OCT.mp4"},
    {id: 2,vURL: "assets/img/proyectos/24NOV.mp4"},
    {id: 3,vURL: "assets/img/proyectos/12DIC.mp4"},
    {id: 4,vURL: "assets/img/proyectos/5ENE.mp4"},
    {id: 5,vURL: "assets/img/proyectos/26FEB.mp4"},
    {id: 6,vURL: "assets/img/proyectos/11MAR.mp4"},
    {id: 7,vURL: "assets/img/proyectos/16ABR.mp4"},
    {id: 8,vURL: "assets/img/proyectos/21JUN.mp4"},
    {id: 9,vURL: "assets/img/proyectos/23JUL.mp4"},
    {id: 10,vURL: "assets/img/proyectos/9AGO.mp4"},
    {id: 11,vURL: "assets/img/proyectos/24SEP.mp4"},
    {id: 12,vURL: "assets/img/proyectos/11OCT.mp4"},
    {id: 13,vURL: "assets/img/proyectos/22NOV.mp4"},
  ];
  
  export default proyectos;